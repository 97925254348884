<template>
  <div class="home">
    <div class="content">
      <div class="content-img">
        <img src="../assets/img-header.png" alt="" />
      </div>
      <div class="content-title">
        <img style="width:29px" src="../assets/img1.png" />
        <span>我们的优势</span>
        <img src="../assets/img1.png" />
        <p>
          金树丫是一家以教育信息化为核心，专注幼教行业的互联网公司，
          致力打造具有前瞻性的家园共育系统。截止目前，已与全国500+家经销商达成合作，
          获得500W+用户认可
        </p>
      </div>
      <div class="content-list">
        <ul v-for="(item, index) in contentList" :key="index">
          <li>
            <div class="content-box">
              <img :src="item.icon" alt="" />
              <span>{{ item.title }}</span>
              <p>{{ item.dsc }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="content-title">
        <img style="width:29px" src="../assets/img1.png" />
        <span>打造前瞻性的家校共育系统</span>
        <img src="../assets/img1.png" />
        <p>
          提供在校时光“班级相册”，针对幼儿园常规教学场景进行相应推荐
          拥有配套的教学活动描述及记录拍照建议，让孩子的在校时光变得更有意义
        </p>
      </div>
      <div class="tips4">
        <div
          @click="index = 1"
          :style="index === 1 ? 'background-color:#FBD646;' : ''"
        >
          幼儿考勤
        </div>
        <div
          @click="index = 2"
          :style="index === 2 ? 'background-color:#FBD646;' : ''"
        >
          实时视频
        </div>
        <div
          @click="index = 3"
          :style="index === 3 ? 'background-color:#FBD646;' : ''"
        >
          活动直播
        </div>
        <div
          @click="index = 4"
          :style="index === 4 ? 'background-color:#FBD646;' : ''"
        >
          班级相册
        </div>
      </div>
      <div v-show="index === 1" class="smallTittle">
        上下学、代接送签到一目了然，实时推送，安全无忧
      </div>
      <div v-show="index === 2" class="smallTittle">
        保证实时音视频直播宝宝在园成长，不同场景一键触达，随时掌握宝宝状态
      </div>
      <div v-show="index === 3" class="smallTittle">
        实时活动直播，万人观看不卡顿，满足不同的直播需求，支持活动分享，高效引流
      </div>
      <div v-show="index === 4" class="smallTittle">
        从入园到毕业，照片/视频轻松展现宝宝的每一个成长点滴
      </div>
      <div class="imgBox">
        <el-carousel height="554px" :interval="num" indicator-position="none" arrow="never">
          <el-carousel-item ref="picSet" v-for="item in list" :key="item" >
            <img
              :src="require('../assets/img/index' + index + '.png')"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="clear"></div>
    <div class="footer">
      <div class="footer-title">
        <span>城市合伙人招募</span>
        <p>
          金树丫城市合伙人由金树丫官方统一招募、经过系统培训、认证授权、服务于幼儿园的专业团队。
        </p>
      </div>
      <div class="footer-phone">
        <img src="../assets/img-phone.png" alt="" />
        <span>联系电话：028-85988298</span>
      </div>
      <div style=" padding-top: 44px; text-align: center;">
        <img src="../assets/home_footer.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data() {
    return {
      contentList: [
        {
          title: "深耕教育行业",
          dsc: "10年教育行业软件研发经验 ，了解行业最深层次的使用需求",
          icon: require("../assets/img-list0.png")
        },
        {
          title: "技术实力雄厚",
          dsc:
            "知名互联网技术公司，核心团队成员有着丰富的项目经验，技术实力雄厚",
          icon: require("../assets/img-list1.png")
        },
        {
          title: "完善服务支撑",
          dsc: "高效敏捷的运营团队及时为代理商提供产品问题解决方案和技术支撑",
          icon: require("../assets/img-list2.png")
        },
        {
          title: "口碑诚信保障",
          dsc: "500+家全国经销商 500W+用户认可，品牌有保障，持续高收益",
          icon: require("../assets/img-list3.png")
        }
      ],
      list:[1,2,3,4],
      timer: null,
      index: 1,
      num:10000
    };
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      this.timer = window.setInterval(() => {
        if (this.index >= 4) {
          this.index = 1;
        } else {
          this.index += 1;
        }
      }, this.num);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.home {
  background: #ffffff;
}
.content {
  clear: both;
  //height: 735px;
  .smallTittle {
    font-size: 20px;
    padding-top: 30px;
    border-top: 1px solid #ebedec;
    width: 1200px;
    margin: auto;
    margin-top: 54px;
    text-align: center;
  }
  .imgBox {
    width: 1200px;
    height: 554px;
    margin: 54px auto 100px auto;
  }
  .footerBox{
    margin-top: 44px;
    width: 1200px;
    // margin: auto;
  }
  .tips4 {
    width: 870px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    div {
      cursor: pointer;
      text-align: center;
      line-height: 68px;
      color: #212121;
      background-color: #e9e9e9;
      border-radius: 40px;
      width: 174px;
      height: 68px;
    }
    div:hover {
      //background-color:#FBD646;
    }
  }
}
.content-img {
  width: 100%;
  text-align: center;
  // height: 537px;
  //background-color:#eeee;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.content-title {
  text-align: center;
  margin-top: 88px;
}
.content-title span {
  width: 180px;
  height: 50px;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  color: #1e1e1e;
}
.content-title p {
  width: 592px;
  height: 69px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #6e6e6e;
  opacity: 1;
  margin: 12px auto;
}
.content-list ul {
  width: 1270px;
  margin: auto;
  margin-top: 20px;
  list-style: none;
}
.content-list ul li {
  display: inline;
  line-height: 40px;
  margin-right: 36px;
  float: left;
}
.content-box {
  width: 273px;
  height: 328px;
  background: #ffffff;
  border: 3px solid #fbd646;
  opacity: 1;
  border-radius: 12px;
  margin-bottom: 115px;
}
.content-box img {
  margin-left: 86px;
  margin-top: 38px;
}
.content-box span {
  margin-left: 70px;
  margin-top: 15px;
  margin-bottom: 30px;
  display: block;
  width: 134px;
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 30px;
  color: #1e1e1e;
  opacity: 1;
}
.content-box p {
  display: block;
  margin-left: 39px;
  text-align: center;
  width: 195px;
  height: 45px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 24px;
  color: #6e6e6e;
  opacity: 1;
}
.clear {
  clear: both;
}
.footer {
  height: 383px;
  // background: #e9e9e9;
  opacity: 1;
}
.footer-title {
  text-align: -webkit-center;
}
.footer-title span {
  display: block;
  height: 59px;
  font-size: 42px;
  font-weight: 600;
  line-height: 59px;
  color: #1e1e1e;
  letter-spacing: 20px;
  opacity: 1;
  text-align: center;
  padding-top: 57px;
}
.footer-title p {
  width: 908px;
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #1e1e1e;
  opacity: 1;
}
.footer-phone {
  margin: 36px auto 0 auto;
  width: 536px;
  height: 91px;
  background: #ffffff;
  border: 5px solid #fbd646;
  opacity: 1;
  border-radius: 49px;
}
.footer-phone img {
  margin-left: 48px;
  margin-top: 23px;
}
.footer-phone span {
  /* margin-top: 25px; */
  // width: 366px;
  display: block;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 42px;
  color: #1e1e1e;
  opacity: 1;
  margin-left: 125px;
  margin-top: -45px;
}

/* .menu ul li{
  margin-left:450px;
width: 30px;
height: 21px;
font-size: 15px;
font-family: PingFang SC;
font-weight: 400;
line-height: 21px;
color: #1E1E1E;
opacity: 1;
} */
</style>
